import { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { GlobalContext } from "../components/Context";
import Header from "../components/Header";
import { client } from "../services/client";
import { routeDictionary, routeDictionaryReverse, routePostFix } from "../static/routeDictionary";

export default function Home() {
    //Hook for the current location
    const location = useLocation();

    //Outlet Context
    const outletContext = useOutletContext();

    //Hook for the global state
    const globalState = useContext(GlobalContext);

    //First name
    const firstName = globalState?.getFirstName();

    //Function from i18next for translation
    const { t: translate } = useTranslation(["home", "routes"]);

    // mediaMatch constant to set max width to 767 pixels.
    const mediaMatch = window.matchMedia("(max-width: 767px)");

    // Boolean state for that checkes if the screen width matches the mad-width pixels.
    const [matches, setMatches] = useState(mediaMatch.matches);

    // Styles for the background image.
    const styles = {
        backgroundImage: matches
            ? `url(${process.env.REACT_APP_STATIC_IMAGES + "/cly-bkgrnd-img.jpg"})`
            : `url(${process.env.REACT_APP_STATIC_IMAGES + "/customers-like-you.jpg"})`,
    };

    //Data for the page
    //https://react-query.tanstack.com/guides/queries
    const getHomeInfo = useQuery(
        ["/lcp-user-management-service/getHomeInfo", outletContext?.rootCustomerNumber],
        () => {
            return client.post("/lcp-user-management-service/getHomeInfo");
        },
        {
            enabled: outletContext?.getBasicAccountInfoDtoList?.isSuccess,
        }
    );

    //Map the quick links
    const mapLinks = () => {
        return getHomeInfo?.data?.data?.quickLinks.map((link) => {
            return (
                <Nav.Item key={link}>
                    <Nav.Link className="lcp-nav-quick-link" as={Link} to={routeDictionary[link]}>
                        <h6 className="lcp-quick-link">{translate(link)}</h6>
                    </Nav.Link>
                </Nav.Item>
            );
        });
    };

    useEffect(() => {
        // Media match on change used to check when pixel screen size changes and set the matches state.
        mediaMatch.onchange = (event) => {
            if (event.matches) {
                setMatches(true);
            } else {
                setMatches(false);
            }
        };
    });

    return (
        <>
            <Helmet>
                <title>{translate(routeDictionaryReverse[location?.pathname], { ns: "routes" }) + routePostFix}</title>
            </Helmet>
            <Header />
            <Container className="lcp lcp-container" fluid>
                <Row
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_STATIC_IMAGES + "/hero-desktop-bkrnd-lp.jpg"}`,
                        backgroundPositionX: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "black",
                    }}
                >
                    <Row className="lcp-home-section">
                        <Col>
                            <h1>{translate("home.title.hello", { firstName: firstName })}</h1>
                            <h4>{translate("home.subtitle")}</h4>
                        </Col>
                    </Row>
                    <Row className="lcp-home-sub-section">
                        <Col>
                            <Nav>
                                <Nav.Item>
                                    <Nav.Link disabled>
                                        <h6>{translate("quickLinks.title")}</h6>
                                    </Nav.Link>
                                </Nav.Item>
                                {getHomeInfo?.isSuccess && mapLinks()}
                            </Nav>
                        </Col>
                    </Row>
                </Row>
                <Row xs={1} sm={2} md={3} lg={4} xl={5} className="justify-content-center lcp-card-row">
                    <Col className="lcp-card-override">
                        <Card id="home-latest-alert" className="lcp-card-small-yellow">
                            <Card.Body>
                                <Row>
                                    <p>
                                        <img
                                            src={process.env.REACT_APP_STATIC_IMAGES + "/alert-icon.png"}
                                            width={40}
                                            height={47.26}
                                            alt=""
                                        />
                                    </p>
                                    <h5>LATEST ALERT</h5>
                                </Row>
                                <br />
                                <Row>
                                    <h4>New LP FlexNet Plus SD-WAN now available!</h4>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={{ offset: 1, span: 10 }}>
                                        <a
                                            className="lcp-button-link-black"
                                            href="https://lightpathfiber.com/services/lp-flexnet"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Learn More
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/yellow-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="lcp-card-override">
                        <Card className="lcp-card-small">
                            <Card.Body>
                                <Card.Title>
                                    <img
                                        width={70}
                                        height={70}
                                        alt="logo"
                                        src={process.env.REACT_APP_STATIC_IMAGES + "/feature-icon.png"}
                                    ></img>
                                </Card.Title>
                                <p className="lcp-card-info">
                                    <span className="lcp-bold">Featured Resources</span>
                                </p>
                                <p className="lcp-card-info">
                                    <a
                                        className="lcp-link"
                                        href="https://portal.lightpathfiber.com/portal-tour"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Portal Tour &gt;
                                    </a>
                                </p>
                                <p className="lcp-card-info">
                                    <a
                                        className="lcp-link"
                                        href="https://lightpathfiber.com/customer-referral"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Referral Program &gt;
                                    </a>
                                </p>
                                <p className="lcp-card-info">
                                    <a
                                        className="lcp-card-info lcp-link"
                                        href="https://lightpathfiber.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        New Lightpath Site &gt;
                                    </a>
                                </p>
                                <p className="lcp-card-info">
                                    <a
                                        className="lcp-card-info lcp-link"
                                        href="/support-documents"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Support Documents &gt;
                                    </a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="lcp-card-override">
                        <Card className="lcp-card-small">
                            <Card.Body>
                                <Card.Title>
                                    <img
                                        width={70}
                                        height={70}
                                        alt="logo"
                                        src={process.env.REACT_APP_STATIC_IMAGES + "/user-icon.png"}
                                    ></img>
                                </Card.Title>
                                <p className="lcp-card-info">
                                    <span className="lcp-bold">Account Support Team</span>
                                </p>
                                <p className="lcp-card-info">
                                    <a
                                        className="lcp-link"
                                        href="/account-support-team"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Visit Account Support Team Page
                                    </a>
                                </p>

                                <p className="lcp-card-info">
                                    <span className="lcp-text">Customer Support:</span>
                                    <br />
                                    <span className="lcp-bold">1-866-611-3434</span>
                                    <br />
                                    <a
                                        className="lcp-link"
                                        href={"mailto: care@lightpathfiber.com"}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Email Customer Care
                                    </a>
                                    <br />
                                    <a
                                        className="lcp-link"
                                        href="/docs/Lightpath_NMC_Escalation_List_10-12-22.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Lightpath NMC Escalation
                                    </a>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="lcp lcp-container">
                <Row className="lcp-hero-image-left-content" style={styles}>
                    <Row>
                        <Col xs={9} sm={{ span: 4, offset: 1 }}>
                            <h2>
                                Next Generation Network <br /> Next Generation Customer Service
                            </h2>
                            <h5>
                                Lightpath is revolutionizing how organizations connect to their digital destinations.
                            </h5>
                            <a
                                className="lcp-button-link-yellow"
                                href="https://lightpathfiber.com/about-us"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Learn More
                                <img
                                    className="lcp-button-link-icon"
                                    src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                    alt=""
                                    width={16}
                                    height={16}
                                />
                            </a>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={9} sm={{ span: 4, offset: 1 }}>
                            <br />
                            <h2>The Lightpath Difference Video</h2>
                            <a
                                className="lcp-button-link-yellow"
                                href="https://vimeo.com/677909097"
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Here
                                <img
                                    className="lcp-button-link-icon"
                                    src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                    alt=""
                                    width={16}
                                    height={16}
                                />
                            </a>
                        </Col>
                    </Row>
                </Row>
            </Container>
            <Container className="lcp lcp-container" fluid>
                <Row xs={1} sm={1} md={2} lg={3} xl={4} className="justify-content-center">
                    <Col className="lcp-card-override-large">
                        <Container className="lcp lcp-container" fluid>
                            <Row
                                style={{
                                    backgroundImage: `url(${
                                        process.env.REACT_APP_STATIC_IMAGES + "/side-image-flexnet.jpg"
                                    }`,
                                    height: "275px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        </Container>
                        <Card className="lcp-card-large">
                            <Card.Body>
                                <Row>
                                    <Col md={{ span: 11, offset: 1 }}>
                                        <h4>LP FlexNet</h4>
                                        <p className="lcp-card-large-sub-heading">
                                            LP FlexNet managed SD-WAN solutions available with Meraki or Versa platforms
                                        </p>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://lightpathfiber.com/services/lp-flexnet"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Read More
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="lcp-card-override-large">
                        <Container className="lcp lcp-container" fluid>
                            <Row
                                style={{
                                    backgroundImage: `url(${
                                        process.env.REACT_APP_STATIC_IMAGES + "/three-col-img02.jpg"
                                    }`,
                                    height: "275px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        </Container>
                        <Card className="lcp-card-large">
                            <Card.Body>
                                <Row>
                                    <Col md={{ span: 11, offset: 1 }}>
                                        <h4>Cloud Connect</h4>
                                        <p className="lcp-card-large-sub-heading">
                                            Dedicated, secure connectivity to a large ecosystem of Cloud providers
                                        </p>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://lightpathfiber.com/applications/cloud-connect"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Read More
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="lcp-card-override-large">
                        <Container className="lcp lcp-container" fluid>
                            <Row
                                style={{
                                    backgroundImage: `url(${
                                        process.env.REACT_APP_STATIC_IMAGES + "/three-col-img03.jpg"
                                    }`,
                                    height: "275px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        </Container>
                        <Card className="lcp-card-large">
                            <Card.Body>
                                <Row>
                                    <Col md={{ span: 11, offset: 1 }}>
                                        <h4>Burstable Bandwidth</h4>
                                        <p className="lcp-card-large-sub-heading">
                                            Affordable flexibility for today and the future
                                        </p>
                                        <a
                                            className="lcp-button-link-yellow"
                                            href="https://lightpathfiber.com/articles/burstable-bandwidth"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Read More
                                            <img
                                                className="lcp-button-link-icon"
                                                src={process.env.REACT_APP_STATIC_IMAGES + "/black-btn-arrow.png"}
                                                alt=""
                                                width={16}
                                                height={16}
                                            />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container className="lcp lcp-container lcp-banner-container" fluid>
                <Row
                    className="lcp-banner-image"
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_STATIC_IMAGES + "/haq-line.png"}`,
                    }}
                ></Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <p>
                            <img
                                width={77}
                                height={92.08}
                                alt="question"
                                src={process.env.REACT_APP_STATIC_IMAGES + "/question-icon.png"}
                            ></img>
                        </p>
                    </Col>
                </Row>
                <Row className="lcp-banner-container-info">
                    <Col className="d-flex justify-content-center">
                        <h1>Have a question?</h1>
                    </Col>
                </Row>
                <br />
                <Row className="lcp-banner-container-info">
                    <Col className="d-flex justify-content-center text-center">
                        <p>Our specialists are here to help you with any business solution assistance you may need.</p>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col className="d-flex justify-content-center">
                        <a className="lcp-button-link-yellow" href={"mailto: care@lightpathfiber.com"}>
                            Contact Us
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
